.initiation-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.initiation-content {
  position: absolute;
  width: 950px;
  height: auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 10px;
}

.seedtest-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.seedtest-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 25px;
}
.seedtest-text > h2 {
  font-size: 40px;
  font-weight: 700;
}
.seedtest-text > p {
  font-size: 16px;
}
.seedtest-text > a {
  font-weight: bold;
  text-decoration: underline;
  color: #000;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .initiation-card {
    margin-top: 40px;
  }
  .seedtest-text > h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .initiation-content {
    width: 100%;
  }
  .initiation-card {
    margin-top: 45px;
  }
  .seedtest-text > h2 {
    font-size: 30px;
  }
  .seedtest-text > p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .initiation-card {
    margin-top: 300px;
  }
  .initiation-content {
    width: 700px;
    border: 2px solid black;
  }
  .seedtest-img img {
    width: 650px;
  }
  .seedtest-text {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .initiation-content {
    width: 600px;
  }
  .seedtest-img img {
    width: 550px;
  }
  .seedtest-text > h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .initiation-card {
    position: static;
    margin-top: 60px;
  }
  .initiation-content {
    position: static;
    width: 500px;
  }
  .seedtest-img img {
    width: 450px;
  }
  .seedtest-text > h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 500px) {
  .initiation-card {
    position: static;
    margin-top: 60px;
  }
  .initiation-content {
    position: static;
    width: 400px;
  }
  .seedtest-img img {
    width: 350px;
  }
  .seedtest-text > h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 400px) {
  .initiation-card {
    position: static;
    margin-top: 60px;
  }
  .initiation-content {
    position: static;
    width: 300px;
  }
  .seedtest-img img {
    width: 250px;
  }
  .seedtest-text > h2 {
    font-size: 25px;
  }
}
